import React from 'react';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

import nitras from '../../assets/images/nitras.png';
import { Carousel, renderIndicator } from 'react-responsive-carousel';
import { Link } from 'gatsby';
import generateAssets from '../../utils/generateAssets';

const BannerCarousel = ({ title, products, uniqueSellingPoints }) => {
	const UnderContent = (props) => (
		<>
			<svg xmlns="http://www.w3.org/2000/svg" width="17.342" height="13.918" viewBox="0 0 17.342 13.918">
				<path
					id="Path_10"
					data-name="Path 10"
					d="M338.448,791.277l4.838,4.838,9.676-9.676"
					transform="translate(-337.033 -785.025)"
					fill="none"
					stroke={props.stroke}
					strokeWidth={props.strokeWidth}
				/>
			</svg>
			{props.content}
		</>
	);

	const Slide = (props) => (
		<div className="slideContainer">
			<img src={props.imgUrl} alt="a checkmark" />
		</div>
	);

	return (
		<div className="carousel">
			<div className="textBtnContainer">
				<div>
					<h1>{title}</h1>
					<img className="nitras-logo" src={nitras} alt="Nitras logo"/>
				</div>
				<div>
					<Link to="https://webshop.primesafety.be/en_US/shop" target="_blank">
						<button className="button">bekijk webshop</button>
					</Link>
				</div>
			</div>
			<Carousel 
				showThumbs={false} 
				showStatus={false} 
				showArrows={false} 
				emulateTouch
				preventMovementUntilSwipeScrollTolerance
				swipeScrollTolerance={30}
				transitionTime={400}
			>
				{products &&
					products.map((product, id) => {
						return (
							<div key={id}>
								<Slide imgUrl={generateAssets(product.images)} />
							</div>
						);
					})}
			</Carousel>
			<div className="bannerUnder">
				<ul>
					{uniqueSellingPoints?.map((point, id) => {
						return (
							<li key={id}>
								<UnderContent stroke="#00aeef" strokeWidth="4" content={point.titlePoint} />
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
};

export default BannerCarousel;
