import React, { useEffect, useState } from 'react';
import generateAssets from '../../utils/generateAssets';

const PartnerLogo = ({ partner }) => {
	return (
		<a>
			<img src={generateAssets(partner.singleImage)} alt={`logo of ${partner.title}`} className="partnerLogo" />
		</a>
	);
};

export default PartnerLogo;
