import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Navigation from '../components/navigation.js';
import Carousel from '../components/home/carousel.js';
import Partners from '../components/home/partners.js';
import Contact from '../components/contact/contact.js';
import Footer from '../components/footer.js';
import InfoSection from '../components/partials/infoSection';

import '../styles/general.scss';
import Category from '../components/home/category';
import MetaData from '../components/partials/metaData';

const HomePage = () => {
	const [cleanData, setCleanData] = useState({});
	const data = useStaticQuery(graphql`
		query HomeQuery {
			Craft {
				entry(title: "home") {
					title
					... on craft_home_home_Entry {
						id
						partnerSectionTitle
						description
						seoImage {
							... on craft_assets_Asset {
								path
							}
						}
						infoSectionHome {
							... on craft_infoSectionHome_block_BlockType {
								id
								imagePosition
								sectionTitle
								sectionText
								sectionImage {
									width
									height
									path
								}
								buttonLink
								linkNewWindow
							}
						}
						heading1
						entriesProducts {
							title
							... on craft_product_default_Entry {
								id
								images {
									width
									height
									path
								}
							}
						}
						entriesPartners {
							title
							... on craft_partner_default_Entry {
								id
								singleImage {
									path
									width
									height
								}
							}
						}
						uniqueSellingPoints {
							... on craft_uniqueSellingPoints_sellingpoint_BlockType {
								id
								titlePoint
							}
						}
					}
				}
				categories {
					... on craft_products_Category {
						id
						icon {
							path
							width
							height
						}
						title
						urlLink
					}
				}
				entries {
					... on craft_partner_default_Entry {
						id
						singleImage {
							path
							width
							height
						}
						title
						sectionHandle
					}
					... on craft_product_default_Entry {
						id
						images {
							path
							width
							height
						}
						title
						sectionHandle
					}
				}
			}
		}
	`);

	useEffect(() => {
		const entry = data?.Craft;
		setCleanData(entry);
	}, [data]);

	return (
		<>
			<main>
				<MetaData title={cleanData?.entry?.title} description={cleanData?.entry?.description} seoImage={cleanData?.entry?.seoImage} />
				<Navigation />
				<Carousel title={cleanData?.entry?.heading1} products={cleanData?.entry?.entriesProducts} uniqueSellingPoints={cleanData?.entry?.uniqueSellingPoints} />
				<Category categories={cleanData?.categories} />
				<InfoSection data={cleanData?.entry?.infoSectionHome} />
				<Partners data={cleanData?.entry?.entriesPartners} />
				<Contact />
				<Footer />
			</main>
		</>
	);
};

export default HomePage;
