import React from 'react';
import PartnerLogo from '../partials/partnerLogo';

const Partners = ({ data }) => {
	return (
		<div className="partners">
			<div className="partnersContainer">
				<h2>Onze partners</h2>
				<div className="partnerLogos">
					{data?.map((partner, i) => {
						return (
							<div key={i}>
								<PartnerLogo partner={partner} i={i} />
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Partners;
