import React from 'react';
import generateAssets from '../../utils/generateAssets';

const Category = ({ categories }) => {
	const CatergoryContainer = (props) => (
		<a className="catergoryContainer" href={props.link} target="_blank">
			<img src={props.imageUrl} alt={`an icon for the category ${props.title}`} />
			<p>{props.title}</p>
		</a>
	);

	return (
		<>
			<div className="categories">
				{categories?.map((category, id) => {
					return (
						<span key={id}>
							<CatergoryContainer 
							title={category.title} 
							imageUrl={generateAssets(category.icon)}
							link={category.urlLink} />
						</span>
					);
				})}
				<a href="https://webshop.primesafety.be/en_US/shop" target="_blank" className="allCategory">
					<p>Bekijk al onze categorieën!</p>
				</a>
			</div>
		</>
	);
};

export default Category;
